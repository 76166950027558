/* # FUNCTIONS
========================================================================== */
import AOS from 'aos';

$(function () {

    "use strict";

    var $window = $(window);
    var $document = $(document);
    var winWidthSm = 991;
    var $body = $('body');


$(document).ready(function () {
    //fullScreenSlider();
    //int_introHeight();
    scroll();
    navigation_menu();
    //pluginElement();
    //sliderHero();
    //sliderAll();
    //containerGridMasonry();
    //scrollCallbackEle();
    //shortcodeElements();

    //new WOW().init();
    //$(function () {

});




    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function scroll() {

        // //Click Event to Scroll to Top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
        $('.scroll-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });

        // Scroll Down Elements
        $('.scroll-down[href^="#"], .scroll-to-target[href^="#"]').on('click', function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };

    // ---------------------------------------------------------------------------------------------------------------------------->
    // NAVIGATION MENU FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function navigation_menu() {

        var navMenuLink = $(".nav-menu > ul > li"),
            //menuIconBtn = $('.menu-dropdown-icon'),
            dropDown_Menu = $('.nav-dropdown'),
            nav_menu_item = $('.nav-menu-item'),
            nav_Mobile_Btn = $(".menu-mobile-btn"),
            nav_menu_wrap = $(".nav-menu");

        // Dropdown Menu Icon
        $('.nav-menu > ul > li:has( > .nav-dropdown)').prepend('<span class="menu-dropdown-icon"></span>');
        $('.nav-dropdown > ul > li:has( > .nav-dropdown-sub)').addClass('sub-dropdown-icon');
        $(".nav-menu > ul > li:has( > .nav-dropdown)").addClass('dd-menu-dropdown-icon');
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').addClass('sub-dropdown-icon');
            };
            if ($(window).width() <= winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').removeClass('sub-dropdown-icon');
            };
        });

        // Dropdown Menu
        navMenuLink.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        $('.menu-dropdown-icon').on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).siblings(".nav-dropdown").stop(true, false).fadeToggle(150);
            }
        });
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $(".nav-dropdown, .nav-dropdown-sub").fadeOut(150);
            }
        });

        // Sub Dropdown Menu
        nav_menu_item.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        nav_menu_item.on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
            }
        });

        // Dropdon Center align
        $window.on('resize', function () {
            dropDown_Menu.each(function (indx) {
                if ($(this).hasClass("center")) {
                    var dropdownWidth = $(this).outerWidth();
                    var navItemWidth = $(this).parents(nav_menu_item).outerWidth();
                    var dropdown_halfWidth = parseInt(dropdownWidth / 2);
                    var navItem_halfWidth = parseInt(navItemWidth / 2);
                    var totlePosition = parseInt(dropdown_halfWidth - navItem_halfWidth);
                    if ($window.width() > winWidthSm) {
                        $(this).css("left", "-" + totlePosition + "px");
                    } else {
                        $(this).css("left", "0");
                    };
                }
            });
        });

        // Mobile Menu Button
        nav_Mobile_Btn.on('click', function (e) {
            nav_menu_wrap.toggleClass('show-on-mobile');
            $(this).toggleClass("active");
            e.preventDefault();
        });

    };


    // ------------------------------------------------------- //
    //   Home Slider
    // ------------------------------------------------------ //
    var homeSlider = $('.home-slider');
    homeSlider.owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 9999,
        autoplayHoverPause: false,
        items: 1,
        margin: 0,
        nav: false,
        dots: true,
        smartSpeed: 2000,

        onRefresh: function () {
            homeSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });


    // ------------------------------------------------------- //
    //   Isotope
    // ------------------------------------------------------ //

    $(window).on('load', function() {

        var Isotope = require('isotope-layout');
        var isogrid = new Isotope('#isogrid', {
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
            transitionDuration: 400
        });

        $('#isotope-filters').on('click', 'button', function () {
            isogrid.arrange({
                filter: $(this).attr('data-filter')
            });
        });

        // BOTONES DE CATEGORIAS
        $('#isotope-filters').each(function (i, currentElement) {
            var $currentElement = $(currentElement);
            $currentElement.on('click', 'button', function () {
              $currentElement.find('.is-checked').addClass('btn-cat-b');
                $currentElement.find('.is-checked').removeClass('btn-cat-a');
                $currentElement.find('.is-checked').removeClass('is-checked');
                $(this).removeClass('btn-cat-b');
                $(this).addClass('btn-cat-a');
                $(this).addClass('is-checked');
            });
        });
    });

    // ------------------------------------------------------- //
    //   Item Slider
    // ------------------------------------------------------ //

    $('.featured-items-slider').owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 9999,
        autoplayHoverPause: true,
        items: 1,
        margin: 0,
        nav: false,
        dots: true,
        smartSpeed: 2000,
        responsiveClass: false
    });

    // ------------------------------------------------------- //
    //   Tooltip
    // ------------------------------------------------------ //

    //$('[data-toggle="tooltip"]').tooltip();


    // AOS

    AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

        disable: function() {
            var maxWidth = 800;
            return window.innerWidth < maxWidth;
          }
    });


});

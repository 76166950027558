global.$ = require("jquery");
global.jQuery = global.$;

// BOOTSTRAP JS & STYLES
import 'bootstrap';
import "./scss/custom/custom-template.scss";

//// CUSTOM STYLES, EXPORTS & JS

import "./_custom-app.js";

/* # PLUGINS
========================================================================== */

import './_plugins.js';

/* # FUNCTIONS
========================================================================== */
import './_functions.js';

